import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 828.000000 943.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,943.000000) scale(0.100000,-0.100000)">


<path d="M4070 8493 c-36 -20 -130 -74 -210 -121 -80 -47 -165 -96 -190 -110
-25 -14 -88 -50 -140 -80 -118 -69 -465 -269 -505 -292 -16 -9 -102 -59 -190
-110 -88 -51 -180 -104 -205 -118 -145 -81 -230 -135 -230 -145 0 -9 275 -172
579 -342 20 -11 66 -37 103 -58 78 -43 96 -45 146 -13 20 12 82 49 137 81 115
67 605 350 650 375 17 9 55 31 85 48 l55 31 80 -45 c44 -25 98 -56 120 -69 22
-12 119 -67 215 -122 96 -55 195 -111 220 -125 25 -14 162 -93 305 -175 143
-83 303 -175 355 -205 52 -30 129 -73 170 -96 41 -24 100 -57 130 -75 31 -17
83 -48 118 -67 65 -37 66 -38 54 -56 -5 -9 -538 -320 -767 -449 -22 -12 -141
-81 -265 -152 -124 -72 -328 -190 -455 -263 -126 -73 -234 -136 -240 -141 -21
-21 -64 -4 -232 92 -98 56 -245 140 -328 187 -82 47 -222 127 -310 178 -174
100 -849 485 -930 529 -27 16 -120 68 -205 118 -321 185 -608 347 -615 347 -9
0 -94 -48 -278 -158 l-107 -63 0 -1703 c0 -1609 1 -1704 18 -1718 13 -12 415
-245 615 -357 66 -37 169 -96 262 -151 50 -28 176 -101 280 -160 105 -59 251
-143 325 -185 74 -43 160 -91 190 -108 30 -16 60 -34 66 -38 6 -5 14 -6 17 -2
4 3 7 205 7 448 0 342 -3 445 -12 452 -7 6 -33 22 -58 35 -25 14 -63 36 -86
49 -23 13 -214 122 -425 243 -211 120 -389 224 -395 230 -9 8 -14 289 -19
1024 -6 911 -5 1013 9 1018 14 6 63 -19 263 -134 39 -23 87 -50 105 -61 18
-10 220 -126 448 -256 228 -131 532 -305 675 -387 143 -82 266 -155 274 -163
12 -12 15 -236 19 -1487 3 -961 8 -1477 14 -1485 6 -7 38 -28 72 -46 57 -32
120 -67 256 -145 l56 -31 153 89 c85 50 156 90 158 90 2 0 20 11 41 23 20 13
159 94 307 179 149 85 340 195 425 245 85 50 263 152 395 228 132 76 265 152
295 170 103 60 395 228 615 355 121 70 276 160 345 200 69 40 148 85 175 101
l50 28 3 1012 c1 582 -2 1017 -7 1022 -5 5 -25 -1 -47 -16 -22 -13 -59 -35
-84 -49 -25 -14 -133 -76 -240 -138 -107 -62 -220 -126 -250 -143 -30 -17 -79
-46 -107 -64 l-53 -34 0 -564 0 -565 -52 -34 c-56 -35 -302 -173 -491 -275
-33 -18 -71 -40 -85 -50 -29 -20 -327 -198 -402 -239 -25 -14 -182 -105 -350
-202 -373 -216 -389 -225 -394 -219 -2 2 -7 459 -11 1016 l-7 1011 21 19 c18
15 162 101 316 187 22 13 90 52 150 88 61 36 207 120 325 188 118 68 262 151
320 185 58 34 159 93 225 130 66 38 170 98 230 133 61 36 200 116 310 180 436
250 651 375 664 388 11 10 14 55 12 231 -1 120 -6 222 -10 226 -9 9 -197 118
-511 297 -124 70 -313 178 -420 240 -207 120 -377 217 -449 258 -38 21 -311
177 -406 232 -265 153 -449 258 -485 277 -25 13 -83 46 -129 73 -46 28 -86 50
-88 50 -2 0 -20 11 -41 23 -20 13 -57 35 -82 49 -25 14 -109 62 -186 107 -78
44 -150 81 -160 81 -11 0 -48 -17 -84 -37z m-938 -1214 c23 -12 68 -11 68 1 0
14 71 12 103 -2 15 -7 27 -19 27 -26 0 -14 -112 -89 -148 -98 -25 -7 -76 16
-146 64 -49 33 -54 48 -21 54 14 3 25 1 26 -6 0 -6 4 -1 9 12 8 18 14 21 37
16 16 -4 36 -11 45 -15z m255 5 c-16 -8 -34 -13 -40 -11 -7 3 -1 9 13 15 38
16 59 13 27 -4z m-1720 -353 c49 -27 139 -78 201 -115 62 -36 115 -66 117 -66
1 0 33 -18 70 -41 74 -45 112 -67 195 -112 30 -17 57 -33 60 -36 3 -4 27 -18
54 -31 26 -14 61 -37 77 -52 17 -15 37 -28 47 -30 9 -2 16 -9 15 -15 -1 -6 11
-13 28 -17 17 -3 44 -16 61 -28 31 -21 31 -22 8 -30 l-22 -8 23 -20 c19 -15
21 -19 7 -20 -9 0 -18 -6 -21 -12 -2 -7 -3 -5 -2 5 3 18 -8 20 -39 8 -18 -7
-18 -8 -3 -14 28 -11 19 -33 -17 -44 -29 -9 -42 -6 -97 20 -35 17 -65 35 -67
39 -2 4 -9 8 -15 8 -7 0 -23 6 -37 14 -14 7 -61 32 -105 56 -44 23 -85 46 -91
51 -15 12 -435 249 -442 249 -2 0 -16 8 -30 18 -15 11 -52 32 -82 49 -128 70
-182 105 -173 114 12 12 176 107 185 108 5 1 47 -21 95 -48z m894 -704 c-10
-9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z"/>
<path d="M3090 7270 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M4186 7088 c-5 -26 -5 -51 -1 -57 4 -7 -7 -11 -29 -11 l-36 0 0 -260
0 -260 33 4 c17 2 41 4 52 5 18 1 18 3 4 14 -9 7 -15 23 -14 37 2 14 -2 30 -7
37 -5 7 -5 13 0 17 5 3 10 88 11 188 1 101 4 192 6 203 3 11 1 45 -3 75 l-8
55 -8 -47z"/>
<path d="M3802 7075 c-1 -27 5 -90 13 -140 38 -238 47 -391 24 -406 -6 -4 -4
-9 7 -13 9 -3 18 -5 19 -4 2 2 4 116 6 253 l4 250 -30 0 c-29 0 -30 2 -36 55
l-6 55 -1 -50z"/>
<path d="M3942 7113 c-37 -7 -44 -34 -19 -84 20 -40 22 -61 25 -256 3 -211 3
-212 -23 -268 -29 -61 -28 -81 8 -94 40 -16 458 -11 484 5 46 29 36 174 -12
174 -27 0 -55 -20 -55 -39 0 -10 -11 -31 -25 -47 -14 -16 -25 -26 -25 -21 0 4
-10 -3 -21 -14 -18 -18 -30 -20 -67 -16 -28 3 -51 12 -59 23 -8 11 -17 17 -21
14 -4 -3 -15 11 -24 30 -12 23 -14 36 -7 41 8 5 8 9 0 14 -13 7 -15 425 -3
425 11 0 32 52 32 77 0 15 -7 24 -22 27 -38 8 -142 13 -166 9z"/>
<path d="M4266 6604 c-10 -26 -7 -104 4 -104 6 0 10 27 10 60 0 62 -3 72 -14
44z"/>
<path d="M4481 6478 l-1 -128 -335 0 -334 0 -4 68 -3 67 -2 -82 c-1 -49 4 -99
12 -125 8 -24 22 -79 30 -123 9 -43 22 -83 29 -87 15 -10 528 -10 544 0 6 4
17 36 23 72 7 36 19 88 27 115 15 51 30 293 20 330 -2 11 -5 -37 -6 -107z"/>
<path d="M5256 4840 c-135 -6 -145 -15 -99 -88 l30 -49 6 -199 c4 -156 9 -209
23 -244 20 -50 57 -106 88 -133 31 -26 106 -63 146 -71 51 -11 220 -17 220 -8
0 4 15 9 33 10 34 3 137 60 137 76 0 5 9 19 20 31 54 57 70 165 70 463 0 51 3
73 10 66 16 -16 14 -359 -3 -429 -49 -201 -211 -295 -460 -264 -143 18 -237
67 -295 155 -57 85 -64 125 -63 362 l1 212 -40 0 c-46 0 -45 6 -30 -120 9 -73
10 -163 6 -335 0 -25 13 -71 36 -125 20 -47 43 -124 51 -172 30 -173 95 -259
234 -310 46 -17 82 -21 178 -22 117 -1 122 0 187 32 110 54 153 122 187 291
11 56 27 114 35 130 9 17 13 44 11 67 -4 31 -3 34 4 14 l8 -25 8 27 c4 15 4
32 0 37 -3 6 -1 11 4 11 6 0 11 21 11 50 0 28 -4 50 -9 50 -6 0 -7 11 -1 28 4
15 14 103 20 196 14 182 13 186 -37 186 -27 0 -28 8 -8 38 20 27 5 62 -28 63
-68 2 -210 -3 -222 -8 -27 -10 -27 -42 -2 -75 42 -55 49 -100 44 -263 -6 -174
-7 -180 -30 -222 -28 -53 -78 -84 -144 -90 -114 -10 -182 47 -204 169 -14 80
-16 229 -5 322 14 107 26 39 26 -145 0 -207 4 -230 40 -269 26 -28 33 -30 97
-30 67 1 84 7 111 42 24 31 31 89 32 273 l2 180 -38 5 c-30 4 -40 10 -45 30
-4 14 -7 -79 -8 -207 -1 -196 -3 -234 -17 -247 -26 -26 -52 -19 -73 21 -7 14
-11 92 -11 229 0 168 -2 205 -12 192 -19 -24 -77 -13 -71 13 3 10 9 19 14 19
14 0 11 36 -3 47 -20 16 -55 18 -172 13z"/>
<path d="M2650 4778 c-33 -18 -36 -41 -10 -78 31 -44 42 -142 35 -325 -7 -179
-10 -198 -37 -238 -27 -38 -20 -69 17 -79 30 -9 338 -2 385 9 87 20 197 85
228 135 32 52 62 148 62 198 0 65 -19 159 -38 195 -42 73 -114 133 -194 159
-71 23 -106 27 -268 32 -116 4 -161 1 -180 -8z m355 -67 c80 -37 131 -116 145
-227 16 -128 -10 -231 -77 -301 -60 -63 -156 -80 -215 -38 -18 12 -18 13 4 13
34 0 108 21 134 39 41 27 77 116 82 204 5 91 -9 158 -44 212 -27 41 -90 76
-136 77 -34 0 -36 13 -5 28 33 16 67 14 112 -7z m-156 -293 c1 -202 -2 -238
-20 -238 -11 0 -12 411 -2 461 7 33 7 33 14 9 4 -14 7 -118 8 -232z"/>
<path d="M2520 4677 c0 -4 11 -72 24 -150 34 -200 39 -247 26 -247 -14 0 -13
-27 2 -32 8 -3 8 -9 0 -23 -11 -20 0 -55 18 -55 10 0 19 509 10 511 -33 4 -80
2 -80 -4z"/>
<path d="M3356 4358 c-3 -13 -6 -33 -6 -44 0 -39 -70 -162 -113 -200 -103 -89
-212 -114 -508 -114 l-209 0 -2 73 -2 72 -3 -70 c-3 -52 2 -89 20 -150 14 -44
31 -102 39 -130 l14 -50 84 -9 c124 -13 373 5 433 32 90 40 166 132 190 233 4
14 14 43 23 65 9 21 18 55 19 74 1 19 7 60 13 90 15 77 24 150 19 150 -3 0 -7
-10 -11 -22z"/>
<path d="M6021 4284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1916 1629 c-3 -34 -6 -154 -6 -265 l0 -204 38 0 37 0 2 260 c1 247
1 260 -17 260 -10 0 -25 2 -33 6 -11 4 -15 -9 -21 -57z"/>
<path d="M3131 1599 c0 -52 0 -97 0 -101 -1 -3 -17 -2 -37 4 -30 8 -39 6 -56
-9 -66 -60 -90 -251 -37 -304 28 -28 77 -25 111 8 l28 27 0 -31 c0 -25 5 -32
25 -35 14 -3 28 0 32 5 4 6 9 125 11 264 l5 253 -26 0 c-14 0 -33 3 -41 6 -15
6 -16 -5 -15 -87z m-3 -171 c18 -18 14 -67 -11 -128 -16 -41 -27 -55 -42 -55
-18 0 -20 7 -19 75 0 41 6 85 12 98 14 24 41 29 60 10z"/>
<path d="M2646 1597 c-9 -44 -9 -45 29 -49 19 -2 35 -4 35 -3 1 0 3 16 6 36 5
36 4 37 -30 41 -31 3 -35 1 -40 -25z"/>
<path d="M2824 1525 c-8 -72 -11 -80 -31 -83 -32 -5 -30 -40 2 -52 25 -9 25
-11 25 -120 l0 -110 30 0 c17 0 33 0 38 0 9 0 9 1 11 124 1 100 2 105 24 108
17 2 23 11 25 36 3 29 1 32 -22 32 -25 0 -26 2 -26 69 l0 69 -34 4 -34 4 -8
-81z"/>
<path d="M3610 1575 c0 -18 -3 -53 -6 -79 -6 -38 -11 -47 -31 -52 -32 -8 -28
-48 5 -52 21 -3 22 -7 22 -118 0 -102 2 -114 18 -114 9 0 27 -3 40 -6 22 -6
22 -6 22 115 l0 121 25 0 c22 0 25 4 25 35 0 31 -3 35 -26 35 -26 0 -26 1 -22
67 l3 67 -37 7 c-37 6 -38 5 -38 -26z"/>
<path d="M4384 1508 c-6 -9 -8 -507 -3 -512 2 -2 21 -6 41 -9 l38 -6 -3 87 -2
87 30 -3 c67 -6 125 95 125 219 0 57 -4 72 -23 95 -28 32 -76 37 -105 11 -25
-23 -32 -21 -32 7 0 19 -6 25 -30 28 -17 3 -33 1 -36 -4z m148 -118 c16 -77
-26 -182 -68 -166 -23 9 -16 140 9 180 24 36 49 30 59 -14z"/>
<path d="M3354 1494 c-31 -15 -74 -78 -74 -108 0 -11 9 -16 28 -16 22 0 30 7
39 31 9 24 17 30 35 27 18 -2 24 -10 26 -39 3 -35 2 -37 -42 -48 -58 -14 -96
-58 -96 -110 0 -59 46 -89 110 -71 19 6 36 6 39 1 4 -5 17 -11 31 -12 25 -4
25 -3 33 91 10 133 2 195 -31 230 -35 37 -60 43 -98 24z m54 -246 c-3 -29 -7
-33 -34 -36 -22 -2 -33 2 -38 14 -9 23 21 54 51 54 21 0 24 -4 21 -32z"/>
<path d="M3860 1502 c-36 -16 -61 -41 -75 -76 -18 -43 -13 -56 20 -56 17 0 26
8 34 31 8 24 15 30 33 27 18 -2 24 -10 26 -39 3 -35 1 -37 -32 -43 -51 -10
-106 -65 -106 -105 0 -44 14 -66 49 -81 23 -9 38 -9 63 -1 18 7 34 7 37 2 3
-5 17 -11 31 -12 24 -4 25 -2 33 71 11 99 2 203 -19 240 -10 16 -31 34 -47 40
-17 5 -30 10 -31 9 -1 0 -8 -3 -16 -7z m38 -254 c-3 -29 -6 -33 -33 -33 -21 0
-31 5 -33 18 -5 25 16 47 45 47 21 0 24 -4 21 -32z"/>
<path d="M4210 1416 c0 -98 -12 -149 -42 -174 -13 -10 -18 -9 -28 3 -10 12
-11 43 -6 125 l7 110 -39 0 -39 0 -7 -118 c-8 -132 -1 -164 45 -188 38 -19 43
-18 83 16 l34 31 7 -30 c5 -22 13 -30 36 -33 l29 -5 0 174 0 173 -24 0 c-13 0
-31 3 -40 6 -14 5 -16 -6 -16 -90z"/>
<path d="M5631 1484 c-28 -23 -35 -25 -57 -15 -38 17 -59 14 -92 -14 -32 -27
-42 -24 -42 12 0 19 -5 23 -30 23 l-30 0 2 -162 3 -163 36 -3 37 -3 4 88 c5
91 29 163 55 163 19 0 26 -42 21 -140 l-4 -88 38 -4 c35 -3 38 -1 39 22 2 88
18 192 33 215 15 22 20 24 33 13 14 -11 15 -31 9 -141 l-6 -128 37 3 38 3 8
89 c15 158 -7 236 -74 251 -19 5 -34 -1 -58 -21z"/>
<path d="M2105 1469 c-85 -75 -86 -251 -2 -313 26 -19 30 -19 69 -6 23 8 50
27 62 43 19 25 19 28 4 44 -15 15 -19 14 -48 -11 -39 -32 -47 -32 -62 -2 -17
39 -5 53 53 60 78 11 87 18 82 68 -6 65 -22 106 -50 128 -36 28 -67 25 -108
-11z m79 -55 c3 -9 6 -31 6 -50 0 -27 -4 -34 -19 -34 -11 0 -27 -3 -37 -7 -15
-6 -16 -1 -10 37 4 24 12 50 18 57 14 18 35 16 42 -3z"/>
<path d="M2395 1466 c-22 -24 -42 -60 -55 -102 -33 -109 -24 -171 29 -198 34
-18 55 -13 95 23 l29 26 -7 -59 c-3 -32 -11 -67 -17 -77 -15 -28 -65 -25 -85
5 -13 22 -19 23 -40 14 -31 -14 -30 -20 6 -62 68 -77 140 -67 180 26 19 45 24
79 30 218 4 91 6 168 3 173 -2 4 -14 7 -25 7 -12 0 -29 6 -37 14 -9 8 -28 18
-44 21 -23 6 -33 2 -62 -29z m90 -53 c13 -12 15 -26 11 -57 -10 -62 -47 -131
-71 -131 -50 0 -13 204 37 205 4 0 14 -8 23 -17z"/>
<path d="M2640 1331 l0 -169 33 -5 c17 -2 35 -1 38 4 3 5 6 79 6 164 1 141 0
156 -17 165 -10 6 -28 10 -39 10 -20 0 -21 -5 -21 -169z"/>
<path d="M4914 1481 c-18 -11 -38 -36 -49 -62 -21 -53 -31 -174 -16 -211 15
-39 68 -70 106 -63 56 10 111 88 85 120 -19 23 -34 18 -54 -16 -13 -21 -24
-29 -39 -27 -21 3 -22 9 -22 84 0 54 5 86 14 98 19 22 41 3 41 -35 0 -26 3
-29 35 -29 32 0 35 2 35 30 0 94 -70 152 -136 111z"/>
<path d="M5180 1493 c-57 -21 -94 -96 -94 -193 -1 -94 20 -135 76 -151 92 -24
153 51 152 191 0 87 -18 124 -71 146 -35 15 -40 15 -63 7z m54 -85 c15 -58 -4
-197 -29 -205 -6 -1 -17 2 -25 7 -18 11 -20 150 -2 193 14 33 47 36 56 5z"/>
<path d="M6123 1463 c-31 -37 -33 -37 -39 -15 -7 25 -39 30 -48 6 -9 -22 -7
-267 2 -280 4 -6 21 -14 38 -17 l31 -6 6 92 c6 98 30 187 52 187 21 0 25 -25
25 -140 l0 -108 33 -5 c17 -2 34 -3 37 0 3 3 5 62 5 133 0 115 -2 131 -21 157
-15 19 -31 29 -55 31 -30 3 -39 -3 -66 -35z"/>
<path d="M6425 1489 c-75 -36 -125 -239 -75 -302 11 -14 29 -29 40 -32 22 -7
59 10 78 37 32 45 38 -12 11 -99 -9 -27 -16 -33 -38 -33 -15 0 -36 9 -46 20
-10 11 -26 20 -36 20 -65 0 1 -98 77 -114 25 -6 37 -2 61 18 45 38 64 120 71
298 5 138 4 148 -14 154 -10 4 -37 15 -60 25 -46 21 -42 21 -69 8z m63 -71
c21 -21 14 -91 -15 -149 -50 -97 -89 -53 -60 67 22 89 44 113 75 82z"/>
<path d="M4670 1256 c0 -7 -3 -33 -6 -58 l-6 -45 43 -6 c53 -7 59 0 59 71 l0
52 -45 0 c-31 0 -45 -4 -45 -14z"/>
<path d="M5857 1218 c-3 -29 -2 -56 1 -60 4 -3 25 -8 47 -10 l40 -3 0 60 0 60
-41 3 -41 3 -6 -53z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
